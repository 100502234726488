import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Paper,
  Chip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, InfoWindow, Circle } from "@react-google-maps/api";
import MaterialTable from "material-table";
import { makeStyles } from "@mui/styles";
import { api } from "common";
import CircularLoading from "../components/CircularLoading";
import { colors } from "../components/Theme/WebTheme";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { FONT_FAMILY, MAIN_COLOR } from "../common/sharedFunctions";
import AddBookings from "./AddDispatch";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: theme.spacing(2),
    height: "calc(100vh - 64px)",
  },
  mapContainer: {
    height: "100%",
    borderRadius: 10,
    overflow: "hidden",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "95%",
    maxWidth: 1400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: 15,
    maxHeight: "90vh",
    overflow: "auto",
    display: "flex",
  },
  addButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: MAIN_COLOR,
    color: colors.WHITE,
    width: '100%',
    borderRadius: 5,
  },
  searchBox: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  bookingForm: {
    width: "40%",
    padding: 0,
    overflowY: "auto",
    maxHeight: "85vh",
  },
  mapSide: {
    width: "60%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  nearbyDriversContainer: {
    marginBottom: theme.spacing(2),
  },
  nearbyDriversList: {
    display: "flex",
    overflowX: "auto",
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),
  },
  driverCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 120,
    borderRadius: 8,
    border: `1px solid ${colors.BORDER_LIGHT}`,
    cursor: "pointer",
    transition: "all 0.2s ease",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: colors.LIGHT_BACKGROUND,
      transform: "translateY(-2px)",
    },
  },
  driverCardSelected: {
    backgroundColor: colors.LIGHT_BACKGROUND,
    borderColor: MAIN_COLOR,
  },
  driverAvatar: {
    backgroundColor: MAIN_COLOR,
    marginBottom: theme.spacing(1),
  },
  driverName: {
    fontWeight: "bold",
    fontSize: 13,
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    fontFamily: FONT_FAMILY,
  },
  driverDetails: {
    fontSize: 11,
    color: colors.MUTED_TEXT,
    textAlign: "center",
    fontFamily: FONT_FAMILY,
  },
  statusChip: {
    marginTop: theme.spacing(0.5),
    height: 20,
    fontSize: 10,
  },
  availableChip: {
    backgroundColor: colors.GREEN,
    color: colors.WHITE,
  },
  busyChip: {
    backgroundColor: colors.RED,
    color: colors.WHITE,
  },
  modalMapContainer: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    minHeight: 400,
  },
}));

const DailyDispatch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [locations, setLocations] = useState([]);
  const [mylocation, setMylocation] = useState(null);
  const [modalLocation, setModalLocation] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedModalMarker, setSelectedModalMarker] = useState(null);
  const [nearbyDrivers, setNearbyDrivers] = useState([]);
  const rootRef = useRef(null);
  const usersdata = useSelector(state => state.usersdata);
  const cars = useSelector(state => state.cartypes.cars);
  const settings = useSelector(state => state.settingsdata.settings);
  const { fetchDrivers, clearFetchDrivers } = api;
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const MAX_NEARBY_DRIVERS = 5; // Aumentado de 3 a 5

  // Obtener ubicaciones de conductores activos
  useEffect(() => {
    dispatch(fetchDrivers('web'));
    return () => {
      dispatch(clearFetchDrivers());
    };
  }, [dispatch, fetchDrivers, clearFetchDrivers]);

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setMylocation(pos);
          setModalLocation(pos);
        },
        error => console.log(error)
      );
    }
  }, [mylocation]);

  useEffect(() => {
    if (usersdata.drivers && bookinglistdata.bookings) {
      const liveBookings = bookinglistdata.bookings.filter(bkg => bkg.status === 'STARTED');
      const drivers = usersdata.drivers;
      let locs = [];
     
      for (let i = 0; i < drivers.length; i++) {
        if (drivers[i].location) {
          let carImage = 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png';
          let bookingRef = null;
          
          for (let j = 0; j < cars.length; j++) {
            if (cars[j].name === drivers[i].carType) {
              carImage = cars[j].image;
            }
          }
          
          for(let j = 0; j < liveBookings.length; j++){
            if(liveBookings[j].driver === drivers[i].uid){
              bookingRef = liveBookings[j].reference;
            }
          }
          
          locs.push({
            id: i,
            lat: drivers[i].location.lat,
            lng: drivers[i].location.lng,
            drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
            carnumber: drivers[i].vehicleNumber,
            cartype: drivers[i].carType,
            carImage: carImage,
            bookingRef: bookingRef,
            fleetadmin: drivers[i].fleetadmin
          });
        }
      }
      
      if (auth.profile.usertype === 'fleetadmin') {
        locs = locs.filter(driver => driver.fleetadmin === auth.profile.uid);
      }
      
      setLocations(locs);
    }
  }, [usersdata.drivers, auth.profile, cars, bookinglistdata.bookings, settings]);

  // Calcular distancia entre dos puntos (en km)
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    try {
      if (!lat1 || !lon1 || !lat2 || !lon2) {
        return Infinity;
      }
      
      const R = 6371; // Radio de la Tierra en km
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    } catch (error) {
      console.error("Error calculating distance:", error);
      return Infinity;
    }
  };

  // Encontrar conductores cercanos cuando se selecciona una dirección de recogida
  useEffect(() => {
    if (pickupAddress && locations.length > 0) {
      const driversWithDistance = locations.map(driver => {
        const distance = calculateDistance(
          pickupAddress.coords.lat,
          pickupAddress.coords.lng,
          driver.lat,
          driver.lng
        );
        
        return {
          ...driver,
          distance: distance,
          distanceText: distance.toFixed(1) + ' km'
        };
      });
      
      // Ordenar por distancia y tomar los 3 más cercanos
      const closest = [...driversWithDistance]
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 3);
      
      setNearbyDrivers(closest);
    } else {
      setNearbyDrivers([]);
    }
  }, [pickupAddress, locations]);

  // Función para manejar el cambio de tipo de vehículo
  const handleVehicleTypeChange = (vehicleType) => {
    setSelectedVehicleType(vehicleType);
    
    // Si tenemos una dirección de recogida, actualizamos los conductores cercanos
    if (pickupAddress) {
      updateNearbyDrivers(pickupAddress, vehicleType);
    }
  };
  
  // Función para actualizar los conductores cercanos basados en el tipo de vehículo
  const updateNearbyDrivers = (address, vehicleType = selectedVehicleType) => {
    try {
      if (!address || !address.coords || !locations.length) {
        console.log("Cannot update nearby drivers: missing address or locations");
        return;
      }
      
      // Calcular distancias para todos los conductores
      const driversWithDistance = locations.map(driver => {
        if (!driver.lat || !driver.lng) {
          console.log("Driver missing coordinates:", driver);
          return {
            ...driver,
            distance: Infinity,
            distanceText: "N/A"
          };
        }
        
        const distance = calculateDistance(
          address.coords.lat,
          address.coords.lng,
          driver.lat,
          driver.lng
        );
        
        return {
          ...driver,
          distance: distance,
          distanceText: distance.toFixed(1) + ' km'
        };
      });
      
      // Ordenar todos los conductores por distancia
      const sortedDrivers = [...driversWithDistance].sort((a, b) => a.distance - b.distance);
      
      // Si hay un tipo de vehículo seleccionado, filtrar por ese tipo
      let filteredDrivers = sortedDrivers;
      if (vehicleType) {
        filteredDrivers = sortedDrivers.filter(driver => 
          driver.cartype && driver.cartype.toLowerCase() === vehicleType.toLowerCase()
        );
      }
      
      // Tomar los 5 más cercanos
      const closest = filteredDrivers.slice(0, MAX_NEARBY_DRIVERS);
      
      setNearbyDrivers(closest);
      
      // Si hay conductores cercanos, centrar el mapa en el punto de recogida
      if (closest.length > 0) {
        setModalLocation({
          lat: address.coords.lat,
          lng: address.coords.lng
        });
      }
    } catch (error) {
      console.error("Error updating nearby drivers:", error);
    }
  };
  
  // Modificar la función handlePickupChange para manejar posibles errores
  const handlePickupChange = (address) => {
    try {
      if (!address || !address.coords || typeof address.coords.lat === 'undefined' || typeof address.coords.lng === 'undefined') {
        console.error("Invalid address object:", address);
        return;
      }
      
      setPickupAddress(address);
      
      setModalLocation({
        lat: address.coords.lat,
        lng: address.coords.lng
      });
      
      // Al seleccionar punto de recogida, mostrar los 5 más cercanos sin filtrar por tipo
      updateNearbyDrivers(address, null);
      
      // Resetear el tipo de vehículo seleccionado
      setSelectedVehicleType(null);
    } catch (error) {
      console.error("Error handling pickup change:", error);
    }
  };

  // Columnas para la tabla de bookings
  const columns = [
    {
      title: t('booking_id'),
      field: 'id',
      render: rowData => <span>{rowData.id}</span>
    },
    {
      title: t('booking_date'),
      field: 'tripdate',
      render: rowData => <span>{new Date(rowData.tripdate).toLocaleDateString()}</span>
    },
    {
      title: t('customer_name'),
      field: 'customer_name'
    },
    {
      title: t('pickup_address'),
      field: 'pickup_address'
    },
    {
      title: t('drop_address'),
      field: 'drop_address'
    },
    {
      title: t('status'),
      field: 'status'
    },
    {
      title: t('actions'),
      field: 'id',
      render: rowData => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => console.log('View booking', rowData.id)}
        >
          {t('view')}
        </Button>
      )
    }
  ];

  // Filtrar bookings según la búsqueda
  const todayBookings = bookinglistdata.bookings ? 
    bookinglistdata.bookings.filter(booking => {
      const bookingDate = new Date(booking.tripdate);
      const today = new Date();
      return bookingDate.toDateString() === today.toDateString();
    }).sort((a, b) => new Date(b.tripdate) - new Date(a.tripdate))
    : [];

  const filteredBookings = todayBookings.filter(booking => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      (booking.customer_name && booking.customer_name.toLowerCase().includes(query)) ||
      (booking.pickup_address && booking.pickup_address.toLowerCase().includes(query)) ||
      (booking.drop_address && booking.drop_address.toLowerCase().includes(query)) ||
      (booking.status && booking.status.toLowerCase().includes(query))
    );
  });

  // Calcular los límites para el mapa basado en los conductores cercanos
  const calculateMapBounds = () => {
    if (nearbyDrivers.length === 0 || !pickupAddress) return null;
    
    // Incluir el punto de recogida y los conductores cercanos
    const points = [
      { lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng },
      ...nearbyDrivers.map(driver => ({ lat: driver.lat, lng: driver.lng }))
    ];
    
    // Encontrar los límites
    const bounds = new window.google.maps.LatLngBounds();
    points.forEach(point => {
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
    });
    
    return bounds;
  };

  return (
    <div className={classes.container} ref={rootRef}>
      {/* Buscador y botón de agregar */}
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid item xs={12} md={9}>
          <TextField
            className={classes.searchBox}
            placeholder={t('search')}
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => setAddModalOpen(true)}
            startIcon={<AddIcon />}
          >
            {t('add_booking')}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{height: 'calc(100% - 80px)'}}>
        {/* Tabla de bookings */}
        <Grid item xs={12} md={9}>
          {bookinglistdata.loading ? (
            <CircularLoading />
          ) : (
            <MaterialTable
              title={t('todays_bookings')}
              columns={columns}
              data={filteredBookings}
              options={{
                rowStyle: {
                  fontSize: 14,
                  fontFamily: FONT_FAMILY
                },
                headerStyle: {
                  fontSize: 16,
                  backgroundColor: colors.Header_Background,
                  color: colors.WHITE,
                  fontFamily: FONT_FAMILY
                },
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 50, 100],
                maxBodyHeight: 'calc(100vh - 280px)',
                minBodyHeight: 'calc(100vh - 280px)'
              }}
            />
          )}
        </Grid>

        {/* Mapa principal */}
        <Grid item xs={12} md={3}>
          <Box className={classes.mapContainer}>
            {mylocation && (
              <GoogleMap
                zoom={10}
                center={mylocation}
                mapContainerStyle={{height: '100%'}}
                onClick={() => setSelectedMarker(null)}
              >
                {locations.map(marker => (
                  <Marker
                    position={{ lat: marker.lat, lng: marker.lng }}
                    key={marker.id}
                    icon={{
                      url: marker.carImage,
                      scaledSize: new window.google.maps.Size(35,25)
                    }}
                    onClick={() => setSelectedMarker(marker)}
                  />
                ))}
                
                {selectedMarker && (
                  <InfoWindow 
                    position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                    options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <div style={{fontFamily: FONT_FAMILY}}>
                      {selectedMarker.drivername}<br/>
                      {selectedMarker.carnumber}<br/>
                      {selectedMarker.bookingRef ? `Booking: ${selectedMarker.bookingRef}` : t('available')}
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Modal para agregar booking con mapa al lado */}
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          {/* Formulario de AddDispatch */}
          <div className={classes.bookingForm}>
            <AddBookings 
              onPickupChange={handlePickupChange}
              onVehicleTypeChange={handleVehicleTypeChange}
            />
          </div>
          
          {/* Mapa al lado del formulario */}
          <div className={classes.mapSide}>
            <Typography variant="h6" style={{marginBottom: 10, fontFamily: FONT_FAMILY}}>
              {t('available_drivers')}
              {selectedVehicleType && (
                <Chip 
                  label={selectedVehicleType}
                  size="small"
                  style={{
                    marginLeft: 8,
                    backgroundColor: MAIN_COLOR,
                    color: colors.WHITE,
                    fontFamily: FONT_FAMILY
                  }}
                  onDelete={() => {
                    setSelectedVehicleType(null);
                    if (pickupAddress) {
                      updateNearbyDrivers(pickupAddress, null);
                    }
                  }}
                />
              )}
            </Typography>
            
            {/* Lista de conductores cercanos (horizontal scroll) */}
            {nearbyDrivers.length > 0 && (
              <div className={classes.nearbyDriversContainer}>
                <Typography variant="subtitle2" style={{marginBottom: 4, fontFamily: FONT_FAMILY}}>
                  {t('nearby_drivers')} ({nearbyDrivers.length})
                </Typography>
                <div className={classes.nearbyDriversList}>
                  {nearbyDrivers.map((driver) => (
                    <div 
                      key={driver.id}
                      className={`${classes.driverCard} ${selectedModalMarker && selectedModalMarker.id === driver.id ? classes.driverCardSelected : ''}`}
                      onClick={() => {
                        setSelectedModalMarker(driver);
                        setModalLocation({
                          lat: driver.lat,
                          lng: driver.lng
                        });
                      }}
                    >
                      <Avatar className={classes.driverAvatar}>
                        <DirectionsCarIcon />
                      </Avatar>
                      <div className={classes.driverName} title={driver.drivername}>
                        {driver.drivername}
                      </div>
                      <div className={classes.driverDetails} title={driver.carnumber}>
                        {driver.carnumber}
                      </div>
                      <div className={classes.driverDetails}>
                        {driver.distanceText}
                      </div>
                      <Chip 
                        label={driver.bookingRef ? t('busy') : t('available')}
                        size="small"
                        className={classes.statusChip}
                        style={{
                          backgroundColor: driver.bookingRef ? colors.RED : colors.GREEN,
                          color: colors.WHITE
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Mapa con conductores */}
            <Box className={classes.modalMapContainer}>
              {modalLocation && (
                <GoogleMap
                  zoom={14}
                  center={modalLocation}
                  mapContainerStyle={{height: '100%'}}
                  onClick={() => setSelectedModalMarker(null)}
                  onLoad={(map) => {
                    // Ajustar el zoom para mostrar solo los conductores cercanos
                    const bounds = calculateMapBounds();
                    if (bounds) {
                      map.fitBounds(bounds, {
                        padding: { top: 50, right: 50, bottom: 50, left: 50 }
                      });
                    }
                  }}
                >
                  {/* Marcadores de conductores cercanos */}
                  {nearbyDrivers.map(marker => (
                    <Marker
                      position={{ lat: marker.lat, lng: marker.lng }}
                      key={marker.id}
                      icon={{
                        url: marker.carImage,
                        scaledSize: new window.google.maps.Size(35,25)
                      }}
                      onClick={() => setSelectedModalMarker(marker)}
                      zIndex={selectedModalMarker && selectedModalMarker.id === marker.id ? 1000 : 1}
                    />
                  ))}
                  
                  {selectedModalMarker && (
                    <InfoWindow 
                      position={{ lat: selectedModalMarker.lat, lng: selectedModalMarker.lng }}
                      options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                      onCloseClick={() => setSelectedModalMarker(null)}
                    >
                      <div style={{fontFamily: FONT_FAMILY}}>
                        {selectedModalMarker.drivername}<br/>
                        {selectedModalMarker.carnumber}<br/>
                        {selectedModalMarker.bookingRef ? `Booking: ${selectedModalMarker.bookingRef}` : t('available')}
                      </div>
                    </InfoWindow>
                  )}
                  
                  {/* Mostrar punto de recogida si está seleccionado */}
                  {pickupAddress && (
                    <>
                      <Marker
                        position={{
                          lat: pickupAddress.coords.lat,
                          lng: pickupAddress.coords.lng
                        }}
                        icon={{
                          url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                        }}
                        zIndex={2000}
                      />
                      <Circle
                        center={{
                          lat: pickupAddress.coords.lat,
                          lng: pickupAddress.coords.lng
                        }}
                        radius={1000} // 1km radio
                        options={{
                          strokeColor: '#FF0000',
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: '#FF0000',
                          fillOpacity: 0.1,
                        }}
                      />
                    </>
                  )}
                </GoogleMap>
              )}
            </Box>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DailyDispatch; 