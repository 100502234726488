export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILED = "USER_SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const USER_DELETED = "USER_DELETED";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";
export const REQUEST_EMAIL_TOKEN = "REQUEST_EMAIL_TOKEN";
export const REQUEST_EMAIL_TOKEN_SUCCESS = "REQUEST_EMAIL_TOKEN_SUCCESS";
export const REQUEST_EMAIL_TOKEN_FAILED = "REQUEST_EMAIL_TOKEN_FAILED";
export const UPDATE_USER_WALLET_HISTORY = "UPDATE_USER_WALLET_HISTORY";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";
export const EDIT_CAR_TYPE = "EDIT_CAR_TYPE";

export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const BOOKING_COMPLETED = "BOOKING_COMPLETED";

export const FETCH_ADDRESSES = "FETCH_ADDRESSES";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILED = "FETCH_ADDRESSES_FAILED";
export const EDIT_ADDRESS = "EDIT_ADDRESS";

export const FETCH_ESTIMATE = "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS = "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED = "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE = "CLEAR_ESTIMATE";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";
export const EDIT_CANCELLATION_REASON = "EDIT_CANCELLATION_REASON";

export const FETCH_PROMOS = "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const FETCH_ALL_USERS_STATIC = "FETCH_ALL_USERS_STATIC";
export const FETCH_ALL_USERS_STATIC_SUCCESS = "FETCH_ALL_USERS_STATIC_SUCCESS";
export const FETCH_ALL_USERS_STATIC_FAILED = "FETCH_ALL_USERS_STATIC_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const FETCH_ALL_DRIVERS = "FETCH_ALL_DRIVERS";
export const FETCH_ALL_DRIVERS_SUCCESS = "FETCH_ALL_DRIVERS_SUCCESS";
export const FETCH_ALL_DRIVERS_FAILED = "FETCH_ALL_DRIVERS_FAILED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const FETCH_DRIVERS_EARNING = "FETCH_DRIVERS_EARNING";
export const FETCH_DRIVERS__EARNING_SUCCESS = "FETCH_DRIVERS__EARNING_SUCCESS";
export const FETCH_DRIVERS__EARNING_FAILED = "FETCH_DRIVERS__EARNING_FAILED";

export const FETCH_BOOKING_DISCOUNT = "FETCH_BOOKING_DISCOUNT";
export const FETCH_BOOKING__DISCOUNT_SUCCESS =
  "FETCH_BOOKING__DISCOUNT_SUCCESS";
export const FETCH_BOOKING__DISCOUNT_FAILED = "FETCH_BOOKING__DISCOUNT_FAILED";
export const EDIT_BOOKING__DISCOUNT = "EDIT_BOOKING__DISCOUNT";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTINGS_ERROR";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_SUCCESS = "UPDATE_WALLET_BALANCE_SUCCESS";
export const UPDATE_WALLET_BALANCE_FAILED = "UPDATE_WALLET_BALANCE_FAILED";
export const CLEAR_PAYMENT_MESSAGES = "CLEAR_PAYMENT_MESSAGES";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";

export const UPDATE_TRIP_PICKUP = "UPDATE_TRIP_PICKUP";
export const UPDATE_TRIP_DROP = "UPDATE_TRIP_DROP";
export const UPDATE_TRIP_CAR = "UPDATE_TRIP_CAR";
export const UPDATE_SELECTED_POINT_TYPE = "UPDATE_SELECTED_POINT_TYPE";
export const CLEAR_TRIP_POINTS = "CLEAR_TRIP_POINTS";

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";
export const ACCEPT_TASK = "ACCEPT_TASK";
export const CANCEL_TASK = "CANCEL_TASK";

export const FETCH_BOOKING_LOCATION = "FETCH_BOOKING_LOCATION";
export const FETCH_BOOKING_LOCATION_SUCCESS = "FETCH_BOOKING_LOCATION_SUCCESS";
export const FETCH_BOOKING_LOCATION_FAILED = "FETCH_BOOKING_LOCATION_FAILED";
export const STOP_LOCATION_FETCH = "STOP_LOCATION_FETCH";
export const STORE_ADRESSES = "STORE_ADRESSES";

export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILED = "FETCH_MESSAGES_FAILED";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const STOP_FETCH_MESSAGES = "STOP_FETCH_MESSAGES";

export const FETCH_WITHDRAWS = "FETCH_WITHDRAWS";
export const FETCH_WITHDRAWS_SUCCESS = "FETCH_WITHDRAWS_SUCCESS";
export const FETCH_WITHDRAWS_FAILED = "FETCH_WITHDRAWS_FAILED";
export const EDIT_WITHDRAWS = "EDIT_WITHDRAWS";

export const UPDATE_GPS_LOCATION = "UPDATE_GPS_LOCATION";

export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";
export const FETCH_LANGUAGE_FAILED = "FETCH_LANGUAGE_FAILED";
export const EDIT_LANGUAGE = "EDIT_LANGUAGE";

export const FETCH_CARS = "FETCH_CARS";
export const FETCH_CARS_SUCCESS = "FETCH_CARS_SUCCESS";
export const FETCH_CARS_FAILED = "FETCH_CARS_FAILED";
export const EDIT_CAR = "EDIT_CAR";

export const SEND_RESET_EMAIL = "SEND_RESET_EMAIL";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILED = "SEND_RESET_EMAIL_FAILED";

export const FETCH_SMTP = "FETCH_SMTP";
export const FETCH_SMTP_SUCCESS = "FETCH_SMTP_SUCCESS";
export const FETCH_SMTP_FAILED = "FETCH_SMTP_FAILED";

export const FETCH_SMSCONFIG = "FETCH_SMSCONFIG";
export const FETCH_SMSCONFIG_SUCCESS = "FETCH_SMSCONFIG_SUCCESS";
export const FETCH_SMSCONFIG_FAILED = "FETCH_SMSCONFIG_FAILED";
export const EDIT_SMSCONFIG = "EDIT_SMSCONFIG";

export const FETCH_SOS = "FETCH_SOS";
export const FETCH_SOS_SUCCESS = "FETCH_SOS_SUCCESS";
export const FETCH_SOS_FAILED = "FETCH_SOS_FAILED";
export const EDIT_SOS = "EDIT_SOS";

export const FETCH_COMPLAIN = "FETCH_COMPLAIN";
export const FETCH_COMPLAIN_SUCCESS = "FETCH_COMPLAIN_SUCCESS";
export const FETCH_COMPLAIN_FAILED = "FETCH_COMPLAIN_FAILED";
export const EDIT_COMPLAIN = "EDIT_COMPLAIN";

export const FETCH_REFERRAL_ID = "FETCH_REFERRAL_ID";
export const FETCH_REFERRAL_ID_SUCCESS = "FETCH_REFERRAL_ID_SUCCESS";
export const EDIT_REFERRAL_ID = "EDIT_REFERRAL_ID";
export const FETCH_REFERRAL_ID_FAILED = "FETCH_REFERRAL_ID_FAILED";

export const FETCH_FLEETADMIN_EARNING = "FETCH_FLEETADMIN_EARNING";
export const FETCH_FLEETADMIN_EARNING_SUCCESS =
  "FETCH_FLEETADMIN_EARNING_SUCCESS";
export const FETCH_FLEETADMIN_EARNING_FAILED =
  "FETCH_FLEETADMIN_EARNING_FAILED";

export const FETCH_CREDIT_CARD = "FETCH_CREDIT_CARD";
export const FETCH_CREDIT_CARD_SUCCESS = "FETCH_CREDIT_CARD_SUCCESS";
export const FETCH_CREDIT_CARD_FAILED = "FETCH_CREDIT_CARD_FAILED";

export const ADD_CREDIT_CARD = "ADD_CREDIT_CARD";
export const ADD_CREDIT_CARD_SUCCESS = "ADD_CREDIT_CARD_SUCCESS";
export const ADD_CREDIT_CARD_FAILED = "ADD_CREDIT_CARD_FAILED";

export const AUTHORIZE_CREDIT_CARD = "AUTHORIZE_CREDIT_CARD";
export const AUTHORIZE_CREDIT_CARD_SUCCESS = "AUTHORIZE_CREDIT_CARD_SUCCESS";
export const AUTHORIZE_CREDIT_CARD_FAILED = "AUTHORIZE_CREDIT_CARD_FAILED";

export const CAPTURE_CREDIT_CARD = "AUTHORIZE_CREDIT_CARD";
export const CAPTURE_CREDIT_CARD_SUCCESS = "AUTHORIZE_CREDIT_CARD_SUCCESS";
export const CAPTURE_CREDIT_CARD_FAILED = "AUTHORIZE_CREDIT_CARD_FAILED";

export const VOID_CREDIT_CARD = "AUTHORIZE_CREDIT_CARD";
export const VOID_CREDIT_CARD_SUCCESS = "AUTHORIZE_CREDIT_CARD_SUCCESS";
export const VOID_CREDIT_CARD_FAILED = "AUTHORIZE_CREDIT_CARD_FAILED";
