import {
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILED,
  UPDATE_BOOKING,
  CANCEL_BOOKING,
  BOOKING_COMPLETED,
} from "../store/types";
import { fetchBookingLocations } from "../actions/locationactions";
import { RequestPushMsg } from "../other/NotificationFunctions";
import store from "../store/store";
import { firebase } from "../config/configureFirebase";
import {
  addActualsToBooking,
  saveAddresses,
  updateDriverQueue,
} from "../other/sharedFunctions";
import { get, onValue, update, off, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

import base64 from "react-native-base64";

export const fetchBookings = () => (dispatch) => {
  const { bookingListRef } = firebase;

  dispatch({
    type: FETCH_BOOKINGS,
    payload: null,
  });

  const userInfo = store.getState().auth.profile;

  off(bookingListRef(userInfo.uid, userInfo.usertype));
  onValue(bookingListRef(userInfo.uid, userInfo.usertype), (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const active = [];
      let tracked = null;
      const bookings = Object.keys(data).map((i) => {
        data[i].id = i;
        data[i].pickupAddress = data[i].pickup.add;
        data[i].dropAddress = data[i].drop.add;
        data[i].discount = data[i].discount ? data[i].discount : 0;
        data[i].cashPaymentAmount = data[i].cashPaymentAmount
          ? data[i].cashPaymentAmount
          : 0;
        data[i].cardPaymentAmount = data[i].cardPaymentAmount
          ? data[i].cardPaymentAmount
          : 0;
        return data[i];
      });
      for (let i = 0; i < bookings.length; i++) {
        if (
          [
            "PAYMENT_PENDING",
            "NEW",
            "ACCEPTED",
            "ARRIVED",
            "STARTED",
            "REACHED",
            "PENDING",
            "PAID",
          ].indexOf(bookings[i].status) != -1
        ) {
          active.push(bookings[i]);
        }
        if (
          ["ACCEPTED", "ARRIVED", "STARTED"].indexOf(bookings[i].status) !=
            -1 &&
          userInfo.usertype == "driver"
        ) {
          tracked = bookings[i];
          fetchBookingLocations(tracked.id)(dispatch);
        }
      }
      dispatch({
        type: FETCH_BOOKINGS_SUCCESS,
        payload: {
          bookings: bookings.reverse(),
          active: active,
          tracked: tracked,
        },
      });
      if (tracked) {
        dispatch({
          type: FETCH_BOOKINGS_SUCCESS,
          payload: null,
        });
      }
    } else {
      dispatch({
        type: FETCH_BOOKINGS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_bookings,
      });
    }
  });
};

export const updateBooking = (booking) => async (dispatch) => {
  console.log('Iniciando updateBooking con estado:', booking.status);
  const {
    auth,
    trackingRef,
    singleBookingRef,
    singleUserRef,
    walletHistoryRef,
    settingsRef,
    userRatingsRef,
  } = firebase;

  try {
    dispatch({
      type: UPDATE_BOOKING,
      payload: booking,
    });

    console.log('Obteniendo configuración');
    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();
    console.log('Configuración obtenida:', settings);

    if (booking.status === "PAYMENT_PENDING") {
      console.log('Actualizando booking con estado PAYMENT_PENDING');
      await update(singleBookingRef(booking.id), booking);
      console.log('Booking actualizado exitosamente');
    }
    if (booking.status === "NEW" || booking.status === "ACCEPTED") {
      console.log('Actualizando booking con estado NEW/ACCEPTED');
      const updatedBooking = updateDriverQueue(booking);
      console.log('Booking actualizado con cola:', updatedBooking);
      await update(singleBookingRef(booking.id), updatedBooking);
      console.log('Booking actualizado exitosamente');
    }
    if (booking.status === "ARRIVED") {
      console.log('Actualizando booking con estado ARRIVED');
      let dt = new Date();
      booking.driver_arrive_time = dt.getTime().toString();
      await update(singleBookingRef(booking.id), booking);
      console.log('Booking actualizado exitosamente');
      
      if (booking.customer_token) {
        try {
          await RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.driver_near,
            screen: "BookedCab",
            params: { bookingId: booking.id },
          });
          console.log('Notificación push enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push:', error);
        }
      }
    }

    if (booking.status === "STARTED") {
      console.log('Actualizando booking con estado STARTED');
      let dt = new Date();
      let localString = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
      let timeString = dt.getTime();
      booking.trip_start_time = localString;
      booking.startTime = timeString;
      await update(singleBookingRef(booking.id), booking);
      console.log('Booking actualizado exitosamente');

      const driverLocation = store.getState().gpsdata.location;
      await push(trackingRef(booking.id), {
        at: new Date().getTime(),
        status: "STARTED",
        lat: driverLocation.lat,
        lng: driverLocation.lng,
        direction: driverLocation.direction,
      });
      console.log('Tracking actualizado exitosamente');

      if (booking.customer_token) {
        try {
          await RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.driver_journey_msg + booking.reference,
            screen: "BookedCab",
            params: { bookingId: booking.id },
          });
          console.log('Notificación push enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push:', error);
        }
      }
    }

    if (booking.status === "REACHED") {
      console.log('Actualizando booking con estado REACHED');
      const driverLocation = store.getState().gpsdata.location;
      await push(trackingRef(booking.id), {
        at: new Date().getTime(),
        status: "REACHED",
        lat: driverLocation.lat,
        lng: driverLocation.lng,
        direction: driverLocation.direction,
      });
      console.log('Tracking actualizado exitosamente');

      let address = await saveAddresses(booking, driverLocation);
      let bookingObj = await addActualsToBooking(booking, address, driverLocation);
      await update(singleBookingRef(booking.id), bookingObj);
      console.log('Booking actualizado exitosamente');

      if (booking.customer_token) {
        try {
          await RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.driver_completed_ride,
            screen: "BookedCab",
            params: { bookingId: booking.id },
          });
          console.log('Notificación push enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push:', error);
        }
      }
    }

    if (booking.status === "PENDING") {
      console.log('Actualizando booking con estado PENDING');
      await update(singleBookingRef(booking.id), booking);
      await update(singleUserRef(booking.driver), { queue: false });
      console.log('Booking y cola actualizados exitosamente');
    }
    
    if (booking.status === "PAID") {
      console.log('Actualizando booking con estado PAID');
      if (booking.booking_from_web) {
        booking.status = "COMPLETE";
      }
      await update(singleBookingRef(booking.id), booking);
      console.log('Booking actualizado exitosamente');
      
      if (booking.driver == auth.currentUser.uid && (booking.prepaid || booking.payment_mode === "cash" || booking.payment_mode === "wallet")) {
        await update(singleUserRef(booking.driver), { queue: false });
        console.log('Cola del conductor actualizada exitosamente');
      }

      if (booking.customer_token) {
        try {
          await RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.success_payment,
            screen: "BookedCab",
            params: { bookingId: booking.id },
          });
          console.log('Notificación push al cliente enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push al cliente:', error);
        }
      }

      if (booking.driver_token) {
        try {
          await RequestPushMsg(booking.driver_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.success_payment,
            screen: "BookedCab",
            params: { bookingId: booking.id },
          });
          console.log('Notificación push al conductor enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push al conductor:', error);
        }
      }
    }
    
    if (booking.status === "COMPLETE") {
      console.log('Actualizando booking con estado COMPLETE');
      await update(singleBookingRef(booking.id), booking);
      console.log('Booking actualizado exitosamente a COMPLETE');
      
      // Asegurarse de que el conductor ya no esté en cola
      if (booking.driver) {
        await update(singleUserRef(booking.driver), { queue: false });
        console.log('Cola del conductor actualizada exitosamente');
      }
      
      // Actualizar calificación del conductor si se proporcionó
      if (booking.rating && booking.driver) {
        try {
          // Obtener calificaciones actuales del conductor
          const driverRatingsSnapshot = await get(userRatingsRef(booking.driver));
          let driverRatings = driverRatingsSnapshot.val() || {};
          
          // Agregar nueva calificación
          driverRatings[booking.id] = {
            rating: booking.rating,
            feedback: booking.feedback || '',
            date: new Date().getTime()
          };
          
          // Guardar calificaciones actualizadas
          await update(userRatingsRef(booking.driver), driverRatings);
          console.log('Calificación del conductor actualizada exitosamente');
        } catch (error) {
          console.error('Error al actualizar calificación del conductor:', error);
        }
      }
      
      // Procesar propina si existe
      if (booking.tipamount && parseFloat(booking.tipamount) > 0) {
        try {
          // Actualizar saldo del conductor
          const driverSnapshot = await get(singleUserRef(booking.driver));
          const driverData = driverSnapshot.val();
          
          if (driverData) {
            const walletBalance = driverData.walletBalance || 0;
            const newBalance = parseFloat(walletBalance) + parseFloat(booking.tipamount);
            
            await update(singleUserRef(booking.driver), { walletBalance: newBalance });
            
            // Registrar transacción de propina
            await push(walletHistoryRef(booking.driver), {
              type: 'Credit',
              amount: parseFloat(booking.tipamount),
              date: new Date().getTime(),
              details: 'Propina del viaje ' + booking.id
            });
            
            console.log('Propina procesada exitosamente');
          }
        } catch (error) {
          console.error('Error al procesar propina:', error);
        }
      }
      
      // Enviar notificación al conductor
      if (booking.driver_token) {
        try {
          await RequestPushMsg(booking.driver_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.booking_complete,
            screen: "DriverTrips",
          });
          console.log('Notificación push al conductor enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push al conductor:', error);
        }
      }
      
      // Enviar notificación al cliente
      if (booking.customer_token) {
        try {
          await RequestPushMsg(booking.customer_token, {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.booking_complete,
            screen: "RideList",
          });
          console.log('Notificación push al cliente enviada exitosamente');
        } catch (error) {
          console.error('Error al enviar notificación push al cliente:', error);
        }
      }
      
      // Despachar acción para actualizar listas de reservas
      console.log('Despachando acción BOOKING_COMPLETED para actualizar listas de reservas');
      dispatch({
        type: BOOKING_COMPLETED,
        payload: booking
      });
      console.log('Acción BOOKING_COMPLETED despachada exitosamente');
    }
    
    console.log('updateBooking completado exitosamente');
    return true;
  } catch (error) {
    console.error('Error en updateBooking:', error);
    console.error('Stack trace:', error.stack);
    throw error;
  }
};

export const cancelBooking = (data) => (dispatch) => {
  const { singleBookingRef, singleUserRef, requestedDriversRef, config } =
    firebase;

  dispatch({
    type: CANCEL_BOOKING,
    payload: data,
  });

  console.log("Entró 01");

  if (!data.booking.transaction_id == "") {
    console.log("Entró 02");
    const voidDetail = {
      transactionId: data.booking.transaction_id,
    };

    console.log(voidDetail);

    let host = `https://${config.projectId}.web.app`;
    let url = `${host}/voidTransaction`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " + base64.encode(config.projectId + ":" + config.appKey),
      },
      body: JSON.stringify(voidDetail),
    });
  }

  update(singleBookingRef(data.booking.id), {
    status: "CANCELLED",
    reason: data.reason,
    cancelledBy: data.cancelledBy,
  }).then(() => {
    if (
      data.booking.driver &&
      (data.booking.status === "NEW" ||
        data.booking.status === "ACCEPTED" ||
        data.booking.status === "ARRIVED")
    ) {
      update(singleUserRef(data.booking.driver), { queue: false });
      if (data.booking.driver_token) {
        RequestPushMsg(data.booking.driver_token, {
          title:
            store.getState().languagedata.defaultLanguage.notification_title,
          msg:
            store.getState().languagedata.defaultLanguage.booking_cancelled +
            data.booking.id,
          screen: "BookedCab",
          params: { bookingId: data.booking.id },
        });
      }

      if (data.booking.customer_token) {
        RequestPushMsg(data.booking.customer_token, {
          title:
            store.getState().languagedata.defaultLanguage.notification_title,
          msg:
            store.getState().languagedata.defaultLanguage.booking_cancelled +
            data.booking.id,
          screen: "BookedCab",
          params: { bookingId: data.booking.id },
        });
      }
    }
    if (data.booking.status === "NEW") {
      remove(requestedDriversRef(data.booking.id));
    }
  });
};

export const updateBookingImage =
  (booking, imageType, imageBlob) => (dispatch) => {
    const { singleBookingRef, bookingImageRef } = firebase;
    uploadBytesResumable(bookingImageRef(booking.id, imageType), imageBlob)
      .then(() => {
        imageBlob.close();
        return getDownloadURL(bookingImageRef(booking.id, imageType));
      })
      .then((url) => {
        if (imageType == "pickup_image") {
          booking.pickup_image = url;
        }
        if (imageType == "deliver_image") {
          booking.deliver_image = url;
        }
        update(singleBookingRef(booking.id), booking);
        dispatch({
          type: UPDATE_BOOKING,
          payload: booking,
        });
      });
  };

export const forceEndBooking = (booking) => async (dispatch) => {
  const {
    trackingRef,
    singleBookingRef,
    singleUserRef,
    walletHistoryRef,
    settingsRef,
  } = firebase;

  dispatch({
    type: UPDATE_BOOKING,
    payload: booking,
  });

  if (booking.status == "STARTED") {
    push(trackingRef(booking.id), {
      at: new Date().getTime(),
      status: "REACHED",
      lat: booking.drop.lat,
      lng: booking.drop.lng,
    });

    const end_time = new Date();
    const diff = (end_time.getTime() - parseFloat(booking.startTime)) / 1000;
    const totalTimeTaken = Math.abs(Math.round(diff));
    booking.trip_end_time =
      end_time.getHours() +
      ":" +
      end_time.getMinutes() +
      ":" +
      end_time.getSeconds();
    booking.endTime = end_time.getTime();
    booking.total_trip_time = totalTimeTaken;

    if (booking.customer_token) {
      RequestPushMsg(booking.customer_token, {
        title: store.getState().languagedata.defaultLanguage.notification_title,
        msg: store.getState().languagedata.defaultLanguage
          .driver_completed_ride,
        screen: "BookedCab",
        params: { bookingId: booking.id },
      });
    }

    update(singleUserRef(booking.driver), { queue: false });

    if (booking.prepaid) {
      const settingsdata = await get(settingsRef);
      const settings = settingsdata.val();

      onValue(
        singleUserRef(booking.driver),
        (snapshot) => {
          let walletBalance = parseFloat(snapshot.val().walletBalance);
          walletBalance = walletBalance + parseFloat(booking.driver_share);
          if (parseFloat(booking.cashPaymentAmount) > 0) {
            walletBalance =
              walletBalance - parseFloat(booking.cashPaymentAmount);
          }
          update(singleUserRef(booking.driver), {
            walletBalance: parseFloat(walletBalance.toFixed(settings.decimal)),
          });

          let details = {
            type: "Credit",
            amount: parseFloat(booking.driver_share).toFixed(settings.decimal),
            date: new Date().getTime(),
            txRef: booking.id,
          };
          push(walletHistoryRef(booking.driver), details);

          if (parseFloat(booking.cashPaymentAmount) > 0) {
            let details = {
              type: "Debit",
              amount: booking.cashPaymentAmount,
              date: new Date().getTime(),
              txRef: booking.id,
            };
            push(walletHistoryRef(booking.driver), details);
          }
        },
        { onlyOnce: true }
      );

      if (booking.customer_token) {
        RequestPushMsg(booking.customer_token, {
          title:
            store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.success_payment,
          screen: "BookedCab",
          params: { bookingId: booking.id },
        });
      }

      if (booking.driver_token) {
        RequestPushMsg(booking.driver_token, {
          title:
            store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.success_payment,
          screen: "BookedCab",
          params: { bookingId: booking.id },
        });
      }
      booking.status = "PAID";
    } else {
      booking.status = "PENDING";
    }

    update(singleBookingRef(booking.id), booking);
  }
};
