import {
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILED,
  UPDATE_BOOKING,
  CANCEL_BOOKING,
  BOOKING_COMPLETED
} from "../store/types";

const INITIAL_STATE = {
  bookings: null,
  active: null,
  tracked: null,
  loading: false,
  error: {
    flag: false,
    msg: null
  }
}

export const bookingslistreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BOOKINGS:
      return {
        ...state,
        loading: true
      };
    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case FETCH_BOOKINGS_FAILED:
      return {
        ...state,
        bookings: null,
        active:null,
        tracked:null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload
        }
      };
    case UPDATE_BOOKING:
      return {
        ...state
      }      
    case CANCEL_BOOKING:
      return {
        ...state
      };
    case BOOKING_COMPLETED:
      // Mover la reserva de la lista de activas a la lista de completadas
      if (state.active && action.payload && action.payload.id) {
        // Filtrar la reserva completada de la lista de activas
        const updatedActive = state.active.filter(booking => booking.id !== action.payload.id);
        
        // Agregar la reserva a la lista de completadas (bookings)
        let updatedBookings = state.bookings ? [...state.bookings] : [];
        const existingIndex = updatedBookings.findIndex(booking => booking.id === action.payload.id);
        
        if (existingIndex >= 0) {
          // Actualizar la reserva existente
          updatedBookings[existingIndex] = action.payload;
        } else {
          // Agregar la nueva reserva completada
          updatedBookings.push(action.payload);
        }
        
        return {
          ...state,
          active: updatedActive,
          bookings: updatedBookings
        };
      }
      return state;
    default:
      return state;
  }
};