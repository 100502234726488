import {
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_SUCCESS,
    CONFIRM_BOOKING_FAILED,
    CLEAR_BOOKING
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { formatBookingObject } from '../other/sharedFunctions';
import { get, onValue, push } from "firebase/database";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING,
        payload: null,
    });
}

export const addBooking = (bookingData) => async (dispatch) => {
    try {
        console.log('addBooking: Iniciando creación de reserva');
        
        const {
            bookingRef,
            settingsRef,
            singleUserRef
        } = firebase;

        dispatch({
            type: CONFIRM_BOOKING,
            payload: bookingData,
        });

        console.log('addBooking: Obteniendo configuración');
        const settingsdata = await get(settingsRef);
        const settings = settingsdata.val();

        console.log('addBooking: Formateando objeto de reserva');
        let data;
        try {
            data = await formatBookingObject(bookingData, settings);
            console.log('addBooking: Objeto de reserva formateado correctamente');
        } catch (formatError) {
            console.error('addBooking: Error al formatear objeto de reserva:', formatError);
            dispatch({
                type: CONFIRM_BOOKING_FAILED,
                payload: "Error al formatear datos de reserva: " + formatError.message,
            });
            return;
        }

        if(bookingData.requestedDrivers){
            console.log('addBooking: Notificando a conductores');
            const drivers = bookingData.requestedDrivers;
            Object.keys(drivers).map((uid)=>{
                onValue(singleUserRef(uid), snapshot => {
                    if (snapshot.val()) {
                        const pushToken = snapshot.val().pushToken;
                        const ios = snapshot.val().userPlatform == "IOS"? true: false
                        if(pushToken){
                            RequestPushMsg(
                                pushToken,
                                {
                                    title: store.getState().languagedata.defaultLanguage.notification_title,
                                    msg: store.getState().languagedata.defaultLanguage.new_booking_notification,
                                    screen: 'DriverTrips'
                                });
                         }
                     }
                }, {onlyOnce: true});
                return drivers[uid];
            })
        }

        console.log('addBooking: Guardando reserva en Firebase');
        try {
            const res = await push(bookingRef, data);
            var bookingKey = res.key;
            console.log('addBooking: Reserva creada exitosamente con ID:', bookingKey);
            
            dispatch({
                type: CONFIRM_BOOKING_SUCCESS,
                payload: {
                    booking_id: bookingKey,
                    mainData: {
                        ...data,
                        id: bookingKey
                    }
                }    
            });
        } catch (firebaseError) {
            console.error('addBooking: Error al guardar reserva en Firebase:', firebaseError);
            dispatch({
                type: CONFIRM_BOOKING_FAILED,
                payload: firebaseError.code + ": " + firebaseError.message,
            });
        }
    } catch (error) {
        console.error('addBooking: Error general:', error);
        dispatch({
            type: CONFIRM_BOOKING_FAILED,
            payload: "Error general: " + error.message,
        });
    }
};

